import React from 'react'
import { ImgComparisonSlider } from "@img-comparison-slider/react";

const Tablet2 = () => {
  return (
    <div className="container">
    <ImgComparisonSlider className='comparator'>
    <img slot="first" src={"tablet/after-1.png"} />
      <img slot="second" src={"tablet/after-2.png"} />
    </ImgComparisonSlider>
  </div>
)
}

export default Tablet2;