import React, { useState, useRef , useEffect} from 'react'

const Carrousel = () => {
  const [activeImage, setActiveImage] = useState(1);
  const [parentWidth, setParentWidth] = useState(0);
  const [parentHeight, setParentHeight] = useState(0);
  const imageRef = useRef(null);

  useEffect(() => {
    if (imageRef.current) {
      const handleImageLoad = () => {
        // Get the width of the image child
        const imageWidth = imageRef.current.offsetWidth;
        const imageHeight = imageRef.current.offsetHeight;
        // Set the width of the parent div to the width of the image child
        setParentWidth(imageWidth);
        setParentHeight(imageHeight);
      };

      // Add the load event listener to the image
      imageRef.current.addEventListener('load', handleImageLoad);

      // Remove the event listener on component unmount (cleanup)
      return () => {
        imageRef.current.removeEventListener('load', handleImageLoad);
      };
    }
  }, []);

  return (
    <div className='container'>
      <div className='imgs-container' style={{width: parentWidth, height: parentHeight}}> 
      <img slot="first" src={"najla-11.png"} className={activeImage !== 1 ? "img" : "img show"} ref={imageRef}/>
      <img slot="first" src={"najla-12.png"} className={activeImage !== 2 ? "img" : "img show"}/>
      <img slot="first" src={"najla-13.png"} className={activeImage !== 3 ? "img" : "img show"}/>
      <img slot="first" src={"najla-14.png"} className={activeImage !== 4 ? "img" : "img show"}/>
      <img slot="first" src={"najla-15.png"} className={activeImage !== 5 ? "img" : "img show"}/>
      <div className='btns btns1' onClick={() => setActiveImage(1)}></div>
      <div className='btns btns2' onClick={() => setActiveImage(2)}></div>
      <div className='btns btns3' onClick={() => setActiveImage(3)}></div>
      <div className='btns btns4' onClick={() => setActiveImage(4)}></div>
      <div className='btns btns5' onClick={() => setActiveImage(5)}></div>
      </div>
    </div>
)
}

export default Carrousel;