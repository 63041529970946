import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LastPage from "./pages/LastPage";
import NewPage from "./pages/NewPage";
import Tablet2 from "./pages/Tablet2";
import Tablet1 from "./pages/Tablet1";

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
        <Route exact path="/swift" element={
            <>
              <div className="desktop">
                <NewPage />
              </div>
              <div className="tablet">
                <Tablet1 />
              </div>
            </>
          } />
          <Route exact path="/slc-hi-light" element={
            <>
              <div className="desktop">
                <LastPage />
              </div>
              <div className="tablet">
                <Tablet2 />
              </div>
            </>
          } />
          <Route path="/" exact element={
            <>
              <div className="desktop">
                <NewPage />
                <LastPage />
              </div>
              <div className="tablet">
                <Tablet1 />
                <Tablet2 />
              </div>
            </>
          } />
          <Route path="/desktop-1" element={<LastPage />} />
          <Route path="/desktop-2" element={<NewPage />} />
          <Route path="/tablet-1" element={<Tablet1 />} />
          <Route path="/tablet-2" element={<Tablet2 />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
