import React from 'react'
import { ImgComparisonSlider } from "@img-comparison-slider/react";

const LastPage = () => {
  return (
    <div className="container">
    <ImgComparisonSlider className='comparator'>
      <img slot="first" src={"image-1.jpg"} />
      <img slot="second" src={"image-2.jpg"} />
    </ImgComparisonSlider>
  </div>
)
}

export default LastPage;